import React from 'react'
import ButtonLink from '../ButtonLink'
import { useTranslation } from 'react-i18next'

function HomeComponent({ userGroup }) {
    const { t } = useTranslation()

    return (
        <div className="container">
            <div className="home">
                {(userGroup === "super-admin" || userGroup === "admin") ? (
                    <ul className="text-center btn-list">
                        <li className="btn-element">
                            <ButtonLink link="/référentiel" linkText={t('Referential')} className="button-large" />
                        </li>
                        <li className="btn-element">
                            <ButtonLink link="/catalogues-fournisseur" linkText={t('Catalogs_by_supplier')} className="button-large" />
                        </li>
                        <li className="btn-element">
                            <ButtonLink link="/tarif" linkText={t('Rate_sheet')} className="button-large" />
                        </li>
                    </ul>
                ) : (
                    <ul className="text-center btn-list">
                        <li className="btn-element">
                            <ButtonLink link="/référentiel" linkText={t('Referential')} className="button-large" />
                        </li>
                        <li className="btn-element">
                            <ButtonLink link="/catalogues-fournisseur" linkText={t('Catalogs_by_supplier')} className="button-large" />
                        </li>
                    </ul>
                )}
            </div>
        </div>
    )
}

export default HomeComponent
