import Header from '../../components/Header';
import FormReference from '../../components/Form/formReference';
import Title from '../../components/Title';
import ButtonLink from '../../components/ButtonLink';
import { useTranslation } from 'react-i18next';
import Footer from '../../components/Footer';

function Reference({ userGroup, dataProject }) {
  const { t } = useTranslation();

  return (
    <>
      <div className="min-height">
        <Title titleName="Référentiel" />
        <Header link="/" titleHeader={t('References')} linkText={t('Back')} />
          <ButtonLink
            link="/"
            linkText={t("Back")}
            className="button-small-back"
          />
        <div className="text-center m-5">
          {/* {(userGroup === "super-admin" || userGroup === "admin") && (
            <ButtonLink
              link="/création-référence"
              linkText={t("Create_a_reference")}
              className="button-mid"
            />
          )} */}
        </div>
        <FormReference userGroup={userGroup} dataProject={dataProject} />
      </div> 
      <Footer />
    </>
  );
}

export default Reference;
