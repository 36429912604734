import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { TbTrashXFilled, TbPencil } from "react-icons/tb";

function DisplayFormat({ dataFormat, handleShowModal, handleUpdate, userGroup }) {
  const { t } = useTranslation()
  if (!dataFormat) { return null }
  return (
    <table className="table table-bordered striped hover h-50 m-3">
      <thead>
        <tr>
          {Object.keys(dataFormat).map((fieldName, index) => (
            <th className="deposit-tracking-col-name align-middle" key={index}>{t(fieldName)}</th>
          ))}
          <th className="deposit-tracking-col-name align-middle" key="actions">Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          {Object.entries(dataFormat).map(([fieldName, fieldData]) => {
            if (fieldData && typeof fieldData === 'object') {
              return (
                <td className="text-nowrap h-auto" key={fieldName}>
                {Object.keys(fieldData).map((key, index) => (
                    typeof fieldData[key] === 'object' && fieldData[key] !== null ? (
                      Object.entries(fieldData[key]).map(([subKey, subValue]) => (
                        <div key={subKey} className="text-start mb-1">
                          <strong>{key} :  </strong> {subKey} : {subValue ? subValue : ""}
                        </div>
                      ))
                    ) : ( 
                      <div key={index}>{key ? key : ""}</div>
                    )
                  ))}
                </td>
              );
            } else {
              return <td className="text-nowrap h-auto" key={fieldName}>{fieldData ? fieldData : ""}</td>;
            }
          })}
          {userGroup === "admin" || userGroup === "super-admin" && (
            <>
            <Button style={
                    {padding : "2px 2px",
                    color: "#fff",
                    backgroundColor: "#032B7C",
                    borderColor: "#fff"
                    }}
                    className="m-2"
                    data-toggle="tooltip"
                    data-placement="top"
                    title={ t("Update format") } 
                    onClick={() => handleUpdate()}>
              <TbPencil size={25} />
            </Button>

            <Button style={
                    {padding : "2px 2px",
                    color: "#fff",
                    backgroundColor: "red",
                    borderColor: "#fff"
                    }}
                    className="m-2"
                    data-toggle="tooltip"
                    data-placement="top"
                    title={ t("Delete format") }
                    onClick={() => handleShowModal()}>
                <TbTrashXFilled size={28} />
            </Button>
          </>
          )}
        </tr>
      </tbody>
    </table>
  );
}

export default DisplayFormat;

