import React from 'react'
import Header from '../../components/Header'
import FormSupplier from '../../components/Form/formSupplier'
import Title from '../../components/Title'
import ButtonLink from '../../components/ButtonLink'
import { useTranslation } from 'react-i18next';
import Footer from '../../components/Footer'


function Supplier({ userGroup, dataProject }) {
    const { t } = useTranslation()
    return (
        <>
            <div className="min-height">
                <Title titleName={t("Catalogs_by_supplier")}/>
                <Header link="/" titleHeader={t('Catalogs_by_supplier')} linkText={t('Back')} />
                <ButtonLink link="/" 
                    linkText={t("Back")} 
                    className="button-small-back"
                />
                <FormSupplier userGroup={userGroup} dataProject={dataProject}/>
            </div> 
            <Footer />
        </>
    )
}

export default Supplier;
