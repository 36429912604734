import React from 'react'
import Header from '../../components/Header/'
import FormLogin from '../../components/Form/login'
import Title from '../../components/Title'
import { useTranslation } from 'react-i18next'
import Footer from '../../components/Footer'

function Login() {
    const { t } = useTranslation()
    return (
        <>
            <div className="min-height">
                <Title titleName={t('Welcome')}/>
                <Header titleHeader={t('Welcome')} />
                <FormLogin />
            </div>
            <Footer />
        </>
    )
  }
  
export default Login