import React from 'react'
import Header from '../../components/Header'
import FormFileRepository from '../../components/Form/formFileRepository'
import Title from '../../components/Title'
import ButtonLink from '../../components/ButtonLink'
import { useTranslation } from 'react-i18next'
import Footer from '../../components/Footer'


function FileRepository({ userGroup, dataProject }) {
  const { t } = useTranslation()
  return (
    <>
      <div className="min-height">
        <Title titleName={t('Deposit_file')} />
        <Header link="/" titleHeader={t('Deposit_file')} linkText={t('Back')} />
          <ButtonLink link="/tarif" 
            linkText={t("Back")} 
            className="button-small-back"
          />
        <div className="text-center m-5">
          {(userGroup === "super-admin" || userGroup === "admin") && (
            <ButtonLink
              link="/tarif/suivi-dépôt"
              linkText={t("Deposit_tracking")}
              className="button-mid"
            />
          )}
          </div>
        <FormFileRepository userGroup={userGroup} dataProject={dataProject} />
      </div>
      <Footer />
    </>
  );
}
  
export default FileRepository