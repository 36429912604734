import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
import { Auth } from 'aws-amplify';

function FormFormatRepository({ userGroup, dataProject }) {
  const { t } = useTranslation();
  const [completeMessage, setCompleteMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const sendFormatFile = async (event) => {
    event.preventDefault();
  
    setErrorMessage("");
    setCompleteMessage("");
    const file = document.getElementById("format_repository").files[0]
    if (file.name.endsWith(".json")) {
      if (file) {
        const reader = new FileReader();
  
        reader.onload = async (e) => {
          const content = JSON.parse(e.target.result);
          const result = verifyingJson(content, setErrorMessage, t);
          if (result) {
            await fetchCreateFormat(content, setCompleteMessage, setErrorMessage, dataProject, t );
          } else {
            return;
          }
        };
  
        reader.readAsText(file);
      }
    } else {
      setErrorMessage(`${t("Extension_not_supported")}: ${file.name}`)
    }
  }

  return (
    <div className="container-fluid mt-5 d-flex justify-content-center">
      <div className="boxed">
        <form onSubmit={sendFormatFile} className="form-control text-center shadow p-3 mb-5 bg-body rounded form-style">
          <div className="row gx-5">
            <div className="col-md-12 my-3">
              <label htmlFor="format_repository" className="mb-2 fw-bold">
                {t('Choose_a_format_to_upload')} :
              </label>
              <input type="file" name="format_repository" id="format_repository" required placeholder={t("Format_name")} accept=".json" className="form-control mt-2 mb-2 input-file-repository" />
              {completeMessage && <div className="alert alert-success mt-4 fw-bold">{completeMessage}</div>}
              {errorMessage && <div className="alert alert-warning mt-4 fw-bold">{errorMessage}</div>}
            </div>
          </div>
          <button type="submit" className="btn my-3">
            {t('Upload')}
          </button>
        </form>
      </div>
    </div>
  );
}

function verifyingJson(content, setErrorMessage, t) {
  let verifyingOk = true
  const requiredKeys = [
    'name',
    'file_type',
    'TTL',
    'has_headers',
    'separator',
    'fixed_field_size',
    'end_of_line_chars',
    'encoding',
    'quoting_char',
    'estimated_order',
    'fields_mapping',
    'file_output'
  ];
  const missingKeys = requiredKeys.filter(key => !content.format.hasOwnProperty(key));
  if (missingKeys.length > 0) {
    setErrorMessage(`${t("The_following_keys_are_missing")}: ${missingKeys.join(', ')}`);
    verifyingOk = false;
  }

  for (const elementName in content.format.fields_mapping) {
    if (content.format.fields_mapping.hasOwnProperty(elementName)) {
      const fieldMapping = content.format.fields_mapping[elementName];
      const requiredFieldKeys = ['column_type', 'output_field', 'is_required', "start_of_length", "end_of_length"];
      
      let missingKeys = [];
      
      for (const key of requiredFieldKeys) {
        if (!fieldMapping.hasOwnProperty(key)) {
          missingKeys.push(key);
        }
      }
      if (missingKeys.length > 0) {
        setErrorMessage(`${t("The_following_keys_are_missing_from_the_element")} "${elementName}": ${missingKeys.join(', ')}`);
        verifyingOk = false; 
      }
    }
    return verifyingOk
  }
  
  for (const elementName in content.format.file_output) {
    if (content.format.file_output.hasOwnProperty(elementName)) {
      const fileOutput = content.format.file_output[elementName];
      const requiredFieldKeys = ['sort', 'catalog_name', 'sort_by'];
    
      let missingKeys = [];
      
      for (const key of requiredFieldKeys) {
        if (!fileOutput.hasOwnProperty(key)) {
          missingKeys.push(key);
        }
      }
      if (missingKeys.length > 0) {
        setErrorMessage(`${t("The_following_keys_are_missing_from_the_element")} "${elementName}": ${missingKeys.join(', ')}`);
        verifyingOk = false; 
      }
    }
    return verifyingOk
  }
  
}

async function fetchCreateFormat(content, setCompleteMessage, setErrorMessage, dataProject, t) {
  const contentValue = content.format
  const supplier = contentValue.supplier
  const format_name = `${supplier}_${contentValue.name}`;
  Object.keys(content.file_output).forEach((key) => {
    if (key === 'catalog_name') {
      content.file_output[key] = `${supplier}_${content.file_output[key]}`;
    }
  });
  const contentBodyJson = JSON.stringify(content);
  setCompleteMessage("");
  setErrorMessage("");
  let url = `${dataProject.startUrl}/create-format`;
  const response = await fetch(url, {
    method: 'PUT',
    headers: {
      "Authorization": `BearerCognito ${dataProject.cognitoToken}`,
      "Content-Type": "application/json",
    },
    body: contentBodyJson,
  });

  let dataResultMessage;
  let dataKeys;
  
  try {
    const data = await response.json();
    dataResultMessage = data.result.message;
    if (data.result.keys) {
      dataKeys = data.result.keys
    }

  } catch (error) {
    setErrorMessage(t("Unexpected_error"));
  }
  if (!response.ok) {
    if (response.status === 400) {
        if (dataResultMessage === "Exception during event parsing") {
            setErrorMessage(t("Error_in_json_format"));
        } else if (dataResultMessage === "The format name already exists for this file type") {
            setErrorMessage(`${t("Error_format")} ${format_name} ${t("already_exist_for_the_type")} ${contentValue.file_type}.`);
        } else if (dataResultMessage === "At least one catalog name already exists") {
            const catalogNames = dataKeys.map((item) => item.catalog_name);
            if (catalogNames.length > 1) {
                setErrorMessage(`${t("Many_catalogs_names")} ${t("already_exist_for_the_supplier")} ${contentValue.supplier}: ${catalogNames.join(', ')}`);
            } else {
                setErrorMessage(`${t("One_catalog_name")} ${catalogNames[0]} ${t("already_exist_for_the_supplier")} ${contentValue.supplier}.`);
            }
        } else if (dataResultMessage === "Attention, you have multiple catalogs with the same name") {
            setErrorMessage(t("Attention_you_have_multiple_catalogs_with_the_same_name"));
        }
    } else if (response.status === 500) {
        setErrorMessage(t("Unexpected_error"));
    } else if (response.status === 0) {
        setErrorMessage(`${t("Download_failed_due_to_a_connection_problem")}.`);
    } else if (response.status === 401) {
        setErrorMessage(t("Unauthorized"));
        console.log(t("Unauthorized"));
        Auth.signOut()
    } else {
        setErrorMessage(t("Unexpected_error"));
    }
  } else {
      setCompleteMessage(t("The_format_has_been_successfully_created"));
  }
}
  

export default FormFormatRepository;
