import { Authenticator } from '@aws-amplify/ui-react';
import { I18n } from 'aws-amplify';
import { useEffect } from 'react';
import traductionFR from '../../assets/traduction/fr/tranlation.json'
import traductionEN from '../../assets/traduction/en/tranlation.json'
import { useTranslation } from 'react-i18next';


I18n.putVocabularies({
  fr: traductionFR,
  en: traductionEN
});

function Authentification() {
  const formFields = {
    signIn: {
      username: {
        required: true,
        order: 1,
      },
      password: {
        required: true,
        order: 2,
      }
     },
  };

  const { t } = useTranslation()

  const Redirection = () => {
    useEffect(() => {
      window.location.replace('/');
    }, []);
    return (
      <div className="text-center">
        <div className="connexion-block">
          <h2>{t("Connexion_in_progress")}</h2>
        </div>
      </div>
    );  
  }
  
  return (
    <div className="d-flex justify-content-center mt-5">
      <Authenticator
        formFields={formFields}
        hideSignUp={true}
      >
        {() => (
          <>
            <Redirection />
          </>
        )}
      </Authenticator>
    </div>
  );  
}

export default Authentification;