import i18next from 'i18next';
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import translationFR from './fr/tranlation.json';
import translationEN from './en/tranlation.json';

const resources = {
    fr: {
        translation : translationFR
    },
    en: {
        translation : translationEN
    },
}
i18next
    .use(initReactI18next)
    .use(LanguageDetector)
    .init({
        fallbackLng: 'fr',
        resources
    })

export default i18next;