import React from 'react'
import Header from '../../components/Header/'
import TarifComponent from '../../components/Tarif/'
import Title from '../../components/Title'
import Footer from '../../components/Footer'
import { useTranslation } from 'react-i18next'
import ButtonLink from '../../components/ButtonLink'

function Tarif({ userGroup }) {
    const { t } = useTranslation()
    return (
        <>
            <div className="min-height">
                <Title titleName="Tarif" />
                <Header link="/" titleHeader={t('Rate_sheet')} linkText={t('Back')} />
                <ButtonLink link="/" 
                    linkText={t("Back")} 
                    className="button-small-back"
                />
                <TarifComponent userGroup={userGroup} />
            </div>
            <Footer />
        </>
    )
  }

export default Tarif