import Error from '../../components/Error'
import Footer from '../../components/Footer';
import Title from '../../components/Title'
import { useTranslation } from 'react-i18next';


function Error403() {
    const { t } = useTranslation()

    return (
        <>
            <div className="min-height">
                <Title titleName={t("Denied_access")} />
                <Error errorCode="403" errorText={t("Denied_access_message")} />
            </div>
            <Footer />
        </>
    )
}  

export default Error403;
