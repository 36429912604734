import React, { useState } from "react";
import { useTranslation } from 'react-i18next';
import * as XLSX from 'xlsx'
import Papa from "papaparse";
import SettingFixedWidthFields from "./SettingFixedWidthFields";
const chardet = require('chardet');
const Buffer = require('buffer').Buffer;
const { FixedWidthParser } = require('fixed-width-parser');

function FileRepository({ inputFileRepositoryAcceptExtension, setLines, setFileEncoding, setQuoteCharacter, setLineEnding, setFileName, extension, setHeadersRows, setFileSeparator, hasHeader, fixedFieldSize, fixedFieldSizeColumnObject, setFixedFieldSizeColumnObject, formatName }) {
  const { t } = useTranslation();
  const [errorMessage, setErrorMessage] = useState("");
  const [inProgressMessage, setInProgressMessage] = useState("");
  const [confirmFixedFieldSettings, setConfirmFixedFieldSettings] = useState(false);
  const handleProcessFile = (content) => {
    setInProgressMessage("")
    handleSetQuote(content)
    Papa.parse(content, {
      header: hasHeader,
      skipEmptyLines: true,
      complete: function (results) {
        const valuesArray = results.data.map(d => Object.values(d));
        const headersArray = Object.keys(results.data[0]);
        const lineBreak = results.meta.linebreak === "\r\n" ? "\n" : results.meta.linebreak;
        setLineEnding(lineBreak);
        setFileSeparator(results.meta.delimiter)
        setHeadersRows(headersArray);
        setInProgressMessage("") 
        if (extension === "TXT" && fixedFieldSize === true && hasHeader === false) {
          setLines(valuesArray.slice(1, 11));
        } else {
          setLines(valuesArray.slice(0, 10));
        }
      },
    });
  }

  const handleSetQuote = (content) => {
    const countDoubleQuote = content.split('"').length - 1;
    const countDoubleQuoteGlued = content.split('""').length - 1;
    const countSingleQuote = content.split("'").length - 1;
    const countSingleQuoteGlued = content.split("''").length - 1;
    const glueDoubleQuote = countDoubleQuoteGlued * 2;
    const glueSimpleQuote = countSingleQuoteGlued * 2;
    const doubleQuote = countDoubleQuote - glueDoubleQuote;
    const simpleQuote = countSingleQuote - glueSimpleQuote;
    
    setQuoteCharacter(doubleQuote > simpleQuote ? '"' : (doubleQuote < simpleQuote ? "'" : ""));
  }

  const handleInputFileRepository = async (event) => {
    setInProgressMessage(t("File_being_processed"));
    const file = event.target.files[0];
    setFileName(file.name);
  
    const readerEncoding = new FileReader();
    readerEncoding.onload = async () => {
      const fileBuffer = Buffer.from(readerEncoding.result);
      const encodingValue = chardet.detect(fileBuffer);
      setFileEncoding(encodingValue);
  
      try {
        let content;
        if (extension === "CSV") {
          const readerCsv = new FileReader();
          readerCsv.onload = (e) => {
            content = e.target.result;
            handleProcessFile(content);
          };
          readerCsv.readAsText(file, encodingValue);
        } else if (extension === "TXT") {
          const readerTxt = new FileReader();
          readerTxt.onload = (e) => {
            content = e.target.result;
            if (fixedFieldSize) {
              const fixedFieldSizeColumnList = Object.values(fixedFieldSizeColumnObject);
              const fixedWidthParser = new FixedWidthParser(fixedFieldSizeColumnList);
              const fixedWidthValueParse = fixedWidthParser.parse(content);
              content = Papa.unparse(fixedWidthValueParse)
              handleProcessFile(content);
            } else {
              handleProcessFile(content);
            }
          };
          readerTxt.readAsText(file, encodingValue);
        } else if (extension === "XLSX" || extension === "XLS") {
          const data = await file.arrayBuffer();
          const workbook = XLSX.read(data, { sheetRows: 10 });
          const worksheet = workbook.Sheets[workbook.SheetNames[0]];
          content = XLSX.utils.sheet_to_csv(worksheet);
          handleProcessFile(content);
        } else {
          setInProgressMessage("");
          setErrorMessage(t("Unsupported_file_extension"));
        }
      } catch (error) {
        setInProgressMessage("");
        setErrorMessage(t("An_error_has_occurred_while_processing_the_file"));
        console.error(t("An_error_has_occurred_while_processing_the_file"), error);
      }
    };
  
    readerEncoding.readAsArrayBuffer(file);
  };
  
  return (
    <div>
      {fixedFieldSize && !confirmFixedFieldSettings  ? (
        <SettingFixedWidthFields
          fixedFieldSizeColumnObject={fixedFieldSizeColumnObject}
          setFixedFieldSizeColumnObject={setFixedFieldSizeColumnObject}
          setConfirmFixedFieldSettings={setConfirmFixedFieldSettings}
          hasHeader={hasHeader}
          formatName={formatName}
        />
      ) : null}
      {fixedFieldSize && confirmFixedFieldSettings ? (
        <>
          <p>{t("The_format_name_is")} : <span className="fw-bold">{formatName}</span></p>
          <input
            onChange={handleInputFileRepository}
            type="file"
            name="file_repository_create_format"
            id="file_repository_create_format"
            required
            placeholder={t("File_name")}
            accept={inputFileRepositoryAcceptExtension}
            className="form-control mt-3"
          />
          {errorMessage && <div className="d-flex justify-content-center"><div className=" alert alert-primary mt-4 fw-bold center">{errorMessage}</div></div>}
          {inProgressMessage && <div className="d-flex justify-content-center"><div className=" alert alert-primary mt-4 fw-bold center">{inProgressMessage}</div></div>}
        </>
      ) : fixedFieldSize ? null : (
        <>
          <p>{t("The_format_name_is")} : <span className="fw-bold">{formatName}</span></p>
          <input
            onChange={handleInputFileRepository}
            type="file"
            name="file_repository_create_format"
            id="file_repository_create_format"
            required
            placeholder={t("File_name")}
            accept={inputFileRepositoryAcceptExtension}
            className="form-control mt-3"
          />
          {errorMessage && <div className="d-flex justify-content-center"><div className=" alert alert-primary mt-4 fw-bold center">{errorMessage}</div></div>}
          {inProgressMessage && <div className="d-flex justify-content-center"><div className=" alert alert-primary mt-4 fw-bold center">{inProgressMessage}</div></div>}
        </>
      )}
    </div>
  );
};

export default FileRepository;
