import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Auth } from 'aws-amplify';
import DisplayFormat from "./DisplayFormat";
import DeleteComponent from "../DeleteFormat/DeleteFormat";
import UpdateFormat from '../UpdateFormat/UpdateFormat';

function GetFormFormat({ userGroup, dataProject }) {
  const { t } = useTranslation()
  const [formValues, setFormValues] = useState({
    format: '',
    file_type: '',
  });
  const [receivedResponse, setReceivedResponse] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessageDeletion, setSuccessMessageDeletion] = useState("");
  const [inProgress, setInProgress] = useState("");
  const [dataFormat, setDataFormat] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [deleteSuccess, setDeleteSuccess] = useState(false);
  const [showUpdateForm, setShowUpdateForm] = useState(false);

  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = () => setShowModal(true);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleDelete = async () => {
    await FetchApiDelFormat(formValues.format.toUpperCase(), formValues.file_type, dataProject, setErrorMessage, t, setDeleteSuccess);
    handleCloseModal();
  };

  const handleUpdate = () => {
    setShowUpdateForm(true);
  }

  const handleSubmitForm = async (event) => {
    event.preventDefault();
    if (userGroup === "super-admin" || userGroup === "admin" || userGroup === "client") {
      setErrorMessage("")
      if (formValues.format === "" || formValues.file_type === "") {
        setErrorMessage(t("Please_enter_a_format") + ".");
        return;
      } else {
        setInProgress(t("Get_format_in_progress"))
        await FetchApiFormat(formValues.format.toUpperCase(), formValues.file_type, setErrorMessage, t, dataProject, setInProgress, setDataFormat, setReceivedResponse);
      }
    } else {
      setErrorMessage(t("Required_rights_to_perform_this_action"));
    }
  };
  return (
    <>
      {!showUpdateForm && (
        <div className="container-fluid mt-5 d-flex justify-content-center align-items-start">
          {receivedResponse && Object.keys(dataFormat).length > 0 ? (
            <div className="container-fluid mt-5 d-flex justify-content-center">
              {!deleteSuccess && (
                <div className="mt-3 justify-content-center h-100 table-responsive text-center">< DisplayFormat dataFormat={dataFormat} 
                                                                                                                handleShowModal={handleShowModal}
                                                                                                                handleUpdate={handleUpdate}
                                                                                                                userGroup={userGroup}/>
                </div>
              )}
              {deleteSuccess && (
                <div className="alert alert-success" role="alert">
                  {t("Deletion successful")}
                </div>
              )}
              < DeleteComponent onDelete={handleDelete} showModal={showModal} handleCloseModal={handleCloseModal} />
            </div>
          ) : (
            <div className="flex">
              <form onSubmit={handleSubmitForm} className="form-control text-center shadow p-3 mb-2 bg-body rounded form-style">
                <div className="row gx-5">
                  <div className="col-md-12 my-3">

                    <label htmlFor="format" className="mb-3 fw-bold">{t('Format')} :</label>
                    <input type="text" name="format" id="format" placeholder={t('Format')} className="form-control" value={formValues.format}
                      onChange={handleInputChange}/>
                     <label htmlFor="file_type" className="mb-3 fw-bold mt-3">{t('File_type')} :</label>
                    <select
                      name="file_type"
                      value={formValues.file_type}
                      onChange={handleInputChange}
                      className="form-control"
                    >
                      <option className="text-center" disabled value="">{t("Select_file_type")}</option>
                      <option className="text-center" value="csv">
                        CSV
                      </option>
                      <option className="text-center" value="txt">
                        TXT
                      </option>
                      <option className="text-center" value="xls">
                        XLS
                      </option>
                      <option className="text-center" value="xlsx">
                        XLSX
                      </option>
                    </select>
                  </div>
                </div>
                <button type="submit" className="btn mb-3">{t('Validate')}</button>
                {errorMessage && (
                  <div className="alert alert-warning mt-4 fw-bold">{errorMessage}</div>
                )}
                {inProgress && (
                  <div className="alert alert-primary mt-4 fw-bold">{inProgress}</div>
                )}
                {successMessageDeletion && (
                  <div className="alert alert-success text-center mx-auto alert-message fw-bold" role="alert">
                    {successMessageDeletion}
                  </div>
                )}
              </form>
            </div>
          )}
        </div>
      )}
      {showUpdateForm && <UpdateFormat dataFormat={dataFormat} userGroup={userGroup} dataProject={dataProject} />}
    </>
  )
}

async function FetchApiFormat(format, file_type, setErrorMessage, t, dataProject, setInProgress, setDataFormat, setReceivedResponse) {
  let url = `${dataProject.startUrl}/get-format?format_name=${format}&file_type=${file_type}`;
  const response = await fetch(url, {
    headers: {
      "Authorization": `BearerCognito ${dataProject.cognitoToken}`,
      "Content-Type": "application/json",
    },
    method: 'GET'
  })
  let dataResultFormat;
  try {
    const data = await response.json();
    dataResultFormat = data.result.format;
  } catch (error) {
    setErrorMessage(t("Unexpected_error"));
  }

  setInProgress("")

  if (!response.ok) {
    if (response.status === 404) {
      setErrorMessage(`${t("The_male")} ${t("format")} ${format.toUpperCase()} ${t("does_not_exist")}.`)
    } else if (response.status === 0) {
      setErrorMessage(`${t("Download_failed_due_to_a_connection_problem")}.`);
    } else if (response.status === 500) {
      setErrorMessage(t("Unexpected_error"));
    } else if (response.status === 401) {
      setErrorMessage(t("Unauthorized"));
      console.log(t("Unauthorized"));
      Auth.signOut()
    } else {
      setErrorMessage(t("Unexpected_error"));
    }
  } else {
    setDataFormat(dataResultFormat)
    setReceivedResponse(true);
  }
}

async function FetchApiDelFormat(format, file_type, dataProject, setErrorMessage, t, setDeleteSuccess) {
  let url = `${dataProject.startUrl}/delete-format`;
  const response = await fetch(url, {
    headers: {
      "Authorization": `BearerCognito ${dataProject.cognitoToken}`,
      "Content-Type": "application/json",
    },
    method: 'DELETE',
    body: JSON.stringify({
      "format_name": format,
      "file_type": file_type
    })
  })
  if (!response.ok) {
    if (response.status === 404) {
      setErrorMessage(`${t("The_male")} ${t("format")} ${format.toUpperCase()} ${t("does_not_exist")}.`)
    } else if (response.status === 0) {
      setErrorMessage(`${t("Download_failed_due_to_a_connection_problem")}.`);
    } else if (response.status === 500) {
      setErrorMessage(t("Unexpected_error"));
    } else if (response.status === 401) {
      setErrorMessage(t("Unauthorized"));
      console.log(t("Unauthorized"));
      Auth.signOut()
    } else {
      setErrorMessage(t("Unexpected_error"));
    }
  } else {
    setDeleteSuccess(true);
  }
}


export default GetFormFormat


