import React from "react";
import { useTranslation } from 'react-i18next';

const FieldMappingOverview = ({ formatData }) => {
  const { t } = useTranslation();
  return (
    <div>
      <h4 className="text-center my-5">{t("File_mapping")}</h4>
      <div className="row">
        {Object.entries(formatData.format.fields_mapping).map(([key, value]) => (
          <div className="col-md-4 mb-5" key={key}>
            <strong>{t("Header")}:</strong> {key}
            {Object.entries(value).map(([fieldMappingKey, fieldMappingValue]) => (
              <React.Fragment key={fieldMappingKey}>
                {fieldMappingKey === 'output_field' && (
                  <div>
                    <strong>{t("output_field_format")}:</strong> {t("data_reference_" + fieldMappingValue)}
                  </div>
                )}
                {fieldMappingKey === 'column_type' && (
                  <div>
                    <strong>{t("column_type_format")}:</strong> {t(fieldMappingValue + "_format")}
                  </div>
                )}
                {fieldMappingKey === 'is_required' && (
                  <div>
                    <strong>{t("is_required_format")}:</strong> {typeof fieldMappingValue === 'boolean' ? (fieldMappingValue ? t("Yes") : t("No")) : t(fieldMappingValue)}
                  </div>
                )}
                {formatData.format.fixed_field_size && fieldMappingKey === 'start_of_length' && (
                  <div>
                    <strong>{t("start_of_length_format")}:</strong> {fieldMappingValue}
                  </div>
                )}
                {formatData.format.fixed_field_size && fieldMappingKey === 'end_of_length' && (
                  <div>
                    <strong>{t("end_of_length_format")}:</strong> {fieldMappingValue}
                  </div>
                )}
              </React.Fragment>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default FieldMappingOverview;
