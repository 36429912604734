import { useTranslation } from "react-i18next";
import { Button, Modal } from "react-bootstrap";

const DeleteComponent = ({ onDelete, showModal, handleCloseModal }) => {
    const { t } = useTranslation()
    return (
      <> 
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
            <Modal.Title>{t ("Deletion confirmation")}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
            {t ("Are you sure you want to delete this format?")}
        </Modal.Body>

        <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseModal}>
            {t ("Cancel")}
            </Button>
            <Button variant="danger" onClick={onDelete}>
            {t ("to_delete")}
            </Button>
        </Modal.Footer>
        </Modal>
      </>
      );
    };

export default DeleteComponent