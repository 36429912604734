import React from "react";
import { useTranslation } from 'react-i18next';

function ResetButton({ setExtension, setLines, setFileSeparator, setHasHeader, setFileInformationsCompleted, setFormatName, setFormatAndSupplierNameCompleted, setUnitPrice, setFileConfirmed, setFixedFieldSize, setFieldMapping, setFileOutput, setFieldMappingConfirmed, setFileOutputConfirmed, setFileEncoding, setSupplier, setQuoteCharacter, setHeadersRows, setFixedFieldSizeColumnObject, setCountry, setLanguage }) {
    const { t } = useTranslation();
    const handleReset = () => {
        setExtension("");
        setLines([]);
        setFileSeparator("");
        setHasHeader(true);
        setFileInformationsCompleted(false);
        setFormatName("")
        setFormatAndSupplierNameCompleted(false)
        setUnitPrice("") 
        setFileConfirmed(false)
        setFixedFieldSize(false)
        setFieldMapping([])
        setFileOutput([])
        setFieldMappingConfirmed(false)
        setFileOutputConfirmed(false)
        setFileEncoding("")
        setSupplier("")
        setQuoteCharacter("")
        setHeadersRows([])
        setFixedFieldSizeColumnObject([])
        setSupplier("")
        setCountry("FR")
        setLanguage("FR")

    };
    return (
        <div className="d-flex justify-content-center">
            <button type="submit" className="btn mt-3" onClick={handleReset}>{t("Reset_creation")}</button>
        </div>
    );
}

export default ResetButton;
