import React from 'react'
import Header from '../../components/Header/'
import FormatComponent from '../../components/Format/'
import Title from '../../components/Title'
import Footer from '../../components/Footer'
import { useTranslation } from 'react-i18next'
import ButtonLink from '../../components/ButtonLink'

function Format({ userGroup }) {
    const { t } = useTranslation()
    return (
        <>
            <div className="min-height">
                <Title titleName="Format" />
                <Header link="/" titleHeader={t('Format')} linkText={t('Back')} />
                <ButtonLink link="/tarif" 
                    linkText={t("Back")} 
                    className="button-small-back"
                />
                <FormatComponent userGroup={userGroup} />
            </div>
            <Footer />
        </>
    )
  }

export default Format