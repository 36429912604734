import React from 'react'
import Header from '../../components/Header'
import FormFormatRepository from '../../components/CreateFormat/formFormatRepository'
import Title from '../../components/Title'
import ButtonLink from '../../components/ButtonLink'
import { useTranslation } from 'react-i18next'
import Footer from '../../components/Footer'


function FormatRepository({ userGroup, dataProject }) {
  const { t } = useTranslation()
    return (
      <>
        <div className="min-height">
          <Title titleName={t('Deposit_format')} />
          <Header link="/" titleHeader={t('Deposit_format')} linkText={t('Back')} />
          <ButtonLink link="/tarif/format" 
            linkText={t("Back")} 
            className="button-small-back"
          />
          <FormFormatRepository userGroup={userGroup} dataProject={dataProject}/>
        </div>
        <Footer />
      </>

    )
  }
  
export default FormatRepository