import React from 'react'
import Error from '../../components/Error'
import Title from '../../components/Title'
import Footer from '../../components/Footer'
import { useTranslation } from 'react-i18next'


function Error404() {
    const { t } = useTranslation()
    return (
        <>
            <div className="min-height">
                <Title titleName={t("Page_not_found")}/>
                <Error errorCode="404" errorText={t("Page_not_found_message")}/>
            </div>
            <Footer />
        </>
    )
  }  

export default Error404