import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Amplify } from 'aws-amplify';
import awsConfig from './aws-exports';

import '../src/assets/traduction/i18n';
import '@aws-amplify/ui-react/styles.css';
import './assets/styles/index.scss';

import Home from './pages/Home';
import FileRepository from './pages/FileRepository';
import Supplier from './pages/Supplier';
import Reference from './pages/Reference';
// import CreateReference from './pages/Reference/create';
import Login from './pages/Login';
import Error404 from './pages/Error/404';
import Error403 from './pages/Error/403';
import Tarif from './pages/Tarif';
import DepositTracking from './pages/DepositTracking';
import FormatFormRepository from "./pages/Format/repository";
// import UpdateReference from './pages/Reference/update';
import CreateFormat from "./pages/Format/create";
import IsAuth from './components/Authentification/isAuth';
import CheckUserGroup from './components/Authentification/checkUserGroup';
import getDataProject from './components/Authentification/getDataProject'
import refreshToken from './components/Authentification/refreshToken'
import GetFormat from "./pages/Format/GetFormat";
import Format from "./pages/Format";

Amplify.configure(awsConfig);
const root = ReactDOM.createRoot(document.getElementById("root"));

async function fetchUserGroup(setUserGroup) {
  try {
    const group = await CheckUserGroup();
    setUserGroup(group);
  } catch (error) {
    console.error(error);
  }
}

const dataProject = await getDataProject();

function App() {
  const [userGroup, setUserGroup] = useState("Disconnected");

  useEffect(() => {
    fetchUserGroup(setUserGroup);
    const intervalId = setInterval(refreshToken, 3300000); // 3300000 => 55 minutes / token expire toutes les heures
    return () => clearInterval(intervalId); 
  }, []);

  return (
    <React.StrictMode>
      <Router>
        <IsAuth>
          <Routes>
            <Route path="/" element={<Home userGroup={userGroup} dataProject={dataProject} />} />
            <Route path="/référentiel" element={<Reference userGroup={userGroup} dataProject={dataProject} />} />
            <Route path="/catalogues-fournisseur" element={<Supplier userGroup={userGroup} dataProject={dataProject} />} />
            <Route path="/connexion" element={<Login userGroup={userGroup} />} />
            {userGroup === 'client' ? (
              <>
                <Route path="/403" element={<Error403 />} />
                <Route path="/tarif/dépôt" element={<Error403 />} />
                <Route path="/tarif/suivi-dépôt" element={<Error403 />} />
                <Route path="/tarif/format" element={<Error403 />} />
                <Route path="/tarif/format/trouver-format" element={<Error403 />} />
                <Route path="/tarif/format/dépôt-format" element={<Error403 />} />
                <Route path="/tarif/format/création-format" element={<Error403 />} />
                {/* <Route path="/création-référence" element={<Error403 />} />
                <Route path="/modification-reference/:reference/:supplier" element={<Error403 />} /> */}
              </>
            ) : (
              <>
                <Route path="/tarif" element={<Tarif userGroup={userGroup} dataProject={dataProject} />} />
                <Route path="/tarif/dépôt" element={<FileRepository userGroup={userGroup} dataProject={dataProject} />} />
                <Route path="/tarif/suivi-dépôt" element={<DepositTracking userGroup={userGroup} dataProject={dataProject} />} />
                <Route path="/tarif/format" element={<Format userGroup={userGroup} dataProject={dataProject} />} />
                <Route path="/tarif/format/trouver-format" element={<GetFormat userGroup={userGroup} dataProject={dataProject} />} />
                <Route path="/tarif/format/dépôt-format" element={<FormatFormRepository userGroup={userGroup} dataProject={dataProject} />} />
                <Route path="/tarif/format/création-format" element={<CreateFormat userGroup={userGroup} dataProject={dataProject} />} />
                {/* <Route path="/création-référence" element={<CreateReference userGroup={userGroup} dataProject={dataProject} />} />
                <Route path="/modification-reference/:reference/:supplier" element={<UpdateReference userGroup={userGroup} dataProject={dataProject} />} /> */}
              </>
            )}
            <Route path="*" element={<Error404 />} />
          </Routes>
        </IsAuth>
      </Router>
    </React.StrictMode>
  );
}

root.render(<App />);