import React from 'react'
import ButtonLink from '../ButtonLink'
import { useTranslation } from 'react-i18next'

function FormatComponent({ userGroup }) {
    const { t } = useTranslation()

    return (
        <div className="container">
            <div className="home">
                {(userGroup === "super-admin" || userGroup === "admin") ? (
                    <ul className="text-center btn-list">
                        <li className="btn-element">
                            <ButtonLink link="/tarif/format/création-format" linkText={t('Create_format')} className="button-large" />
                        </li>
                        <li className="btn-element">
                            <ButtonLink link="/tarif/format/dépôt-format" linkText={t('Deposit_format')} className="button-large" />
                        </li>
                        <li className="btn-element">
                            <ButtonLink link="/tarif/format/trouver-format" linkText={t('Get_format')} className="button-large" />
                        </li>
                    </ul>
                ) : null}
            </div>
        </div>
    )    
}

export default FormatComponent
